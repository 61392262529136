import React, { useState } from 'react';
import { Button, Dialog, FormControlLabel, Radio, RadioGroup, TextField } from '@material-ui/core';
import { changeCurrencyUnit, getPriceFromProduct } from '../../utils/currency';
import { ajax } from '../../utils/jQuery';
import { GRAPHQL_URL, ProductBillingType } from '../../constants/constants';
import { isNil } from '@fxts/core';
import { DateTime } from 'luxon';

export const PaymentDialog = (props) => {
    const {
        product,
        extension,
        phoneNumber,
        name,
        onChangeName,
        onChangePhoneNumber,
        onChangePayMethod,
        onKeyDownOnPaymentDialog,
        isDialogOpen,
        closePaymentDialog,
        purchase,
        authCode,
        onChangeAuthCode,
        isSentVerifiedCode,
        setIsSentVerifiedCode,
        paymentType,
        firstDate,
    } = props;

    const isTextBook = product?.isTextbook ?? false;
    const [verifyAuthCodeLoading, setVerifyAuthCodeLoading] = useState(false);

    const handleClickVerifyCode = () => {
        setVerifyAuthCodeLoading(true);
        const body = {
            operationName: 'SendAuthVerifySMS',
            variables: { input: { authenticationType: 'SINGUP', phoneNumber } },
            query: `mutation SendAuthVerifySMS($input: SendAuthVerifySMSInput!) {\tsendAuthVerifySMS(input: $input) {\t\tresult\t\t__typename\t}}`,
        };
        ajax({
            type: 'POST',
            url: GRAPHQL_URL,
            data: JSON.stringify(body),
            success: function () {
                setIsSentVerifiedCode(true);
                setVerifyAuthCodeLoading(false);
            },
            contentType: 'application/json',
            dataType: 'json',
        });
    };

    return (
        <Dialog
            PaperProps={{ style: { maxWidth: 380, width: '100%' } }}
            onKeyDown={onKeyDownOnPaymentDialog}
            open={isDialogOpen}
            onBackdropClick={closePaymentDialog}
        >
            <div style={{ padding: 24, display: 'flex', flexDirection: 'column', gap: 8 }}>
                <div
                    style={{
                        fontSize: '18px',
                        fontWeight: 'bold',
                        marginBottom: '10px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 8,
                    }}
                >
                    <div>{product && product.name}</div>
                    {(product?.billingType === ProductBillingType.RECURRING || product?.billingType === ProductBillingType.MONTHLY_BILLING) &&
                        !isNil(firstDate) && (
                            <div
                                style={{
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                }}
                            >
                                개강일 {DateTime.fromJSDate(firstDate).toFormat('yyyy년 MM월 dd일')}
                            </div>
                        )}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                    {product?.billingType === ProductBillingType.MONTHLY_BILLING && (
                        <div
                            style={{
                                fontSize: '16px',
                                fontWeight: 'bold',
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            {product?.billingType === ProductBillingType.MONTHLY_BILLING && <div>이번 결제 금액 </div>}
                            <div>{product && changeCurrencyUnit(getPriceFromProduct(product, firstDate, 20))}원</div>
                        </div>
                    )}
                    <div
                        style={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        {product?.billingType === ProductBillingType.MONTHLY_BILLING && <div>매달 결제 금액 </div>}
                        <div>{product && changeCurrencyUnit(product.price)}원</div>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 8, padding: '8px 0px' }}>
                    <TextField
                        placeholder={extension ? '학생 이름' : '학부모 이름'}
                        name="name"
                        fullWidth={true}
                        onChange={onChangeName}
                        error={!/^[ㄱ-ㅎㅏ-ㅣ가-힣a-z0-9_-].{1,10}$/.test(name)}
                        value={name}
                    />
                    <TextField
                        placeholder={extension ? '학생 연락처' : '학부모 연락처'}
                        name="phoneNumber"
                        type="number"
                        fullWidth={true}
                        onChange={onChangePhoneNumber}
                        error={!/^(?=.*[0-9]).{10,11}$/.test(phoneNumber)}
                        value={phoneNumber}
                        InputProps={
                            (!extension && {
                                endAdornment: (
                                    <Button
                                        style={{ fontSize: 12, width: 120, padding: 4, marginBottom: 6 }}
                                        variant="outlined"
                                        disabled={phoneNumber.length > 11 || phoneNumber.length < 10 || verifyAuthCodeLoading}
                                        onClick={handleClickVerifyCode}
                                    >
                                        {isSentVerifiedCode ? '재전송' : '인증번호 전송'}
                                    </Button>
                                ),
                            }) as any
                        }
                    />
                    {isSentVerifiedCode && (
                        <TextField
                            placeholder="인증번호"
                            name="phoneVerifyCode"
                            type="number"
                            fullWidth={true}
                            value={authCode}
                            onChange={onChangeAuthCode}
                            disabled={verifyAuthCodeLoading}
                        />
                    )}
                </div>
                {!isTextBook && product && product.isRecurring !== 1 && (
                    <div>
                        <RadioGroup onChange={onChangePayMethod} defaultValue={'card'}>
                            <FormControlLabel value="card" control={<Radio />} label="신용카드" />
                            <FormControlLabel value="vbank" control={<Radio />} label="가상계좌" />
                            <FormControlLabel value="kakaopay" control={<Radio />} label="카카오페이" />
                        </RadioGroup>
                    </div>
                )}
                <Button
                    color={'secondary'}
                    variant={'contained'}
                    style={{
                        width: '100%',
                        position: 'relative',
                    }}
                    onClick={purchase}
                >
                    결제
                </Button>
            </div>
        </Dialog>
    );
};

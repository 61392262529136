import React, { useEffect, useState } from 'react';
import { Footer } from '../../components/common/Footer';
import { MAX_DESKTOP_WIDTH, ProductBillingType, REST_URL } from '../../constants/constants';
import { PaymentDialog } from '../../components/payment/PaymentDialog';
import { regExp } from '../../utils/reg-exp';
import { googleAnalyticsConfig, googleAnalyticsEvent } from '../../utils/google-analytics';
import { ajax } from '../../utils/jQuery';
import { ProductList } from '../../components/payment/ProductList';
import { MerchantType, purchaseWithIamport } from '../../utils/purchase';
import { PaymentState } from '../payment/Payment';
import { PaymentPageType } from '../../constants/payment';
import { ProductRulesAccordion } from '../../components/purchase/ProductRulesAccordion';
import { isNil } from '@fxts/core';
import { DafaultPage } from '../Default';
import { isDatePlusDaysBeforeToday } from '../../utils/time';

function PaymentExtension(props) {
    const initialStates: PaymentState = {
        selectedProduct: undefined,
        products: [],
        isDialogOpen: false,
        name: '',
        phoneNumber: '',
        payMethod: 'card',
        contentWidth: (window.innerWidth < MAX_DESKTOP_WIDTH ? window.innerWidth : MAX_DESKTOP_WIDTH) / 100,
        authCode: '',
        isSentVerifiedCode: false,
    };

    const { isMobile, paymentType, token, direct, recurring, queryParams, options, firstDate } = props;
    const extension = true;

    const [selectedProduct, setSelectedProduct] = useState(initialStates.selectedProduct);
    const [products, setProducts] = useState(initialStates.products);
    const [isDialogOpen, setDialogOpen] = useState(initialStates.isDialogOpen);
    const [name, setName] = useState(initialStates.name);
    const [phoneNumber, setPhoneNumber] = useState(initialStates.phoneNumber);
    const [payMethod, setPayMethod] = useState(initialStates.payMethod);
    const [contentWidth, setContentWidth] = useState(initialStates.contentWidth);
    const [authCode, setAuthCode] = useState(initialStates.authCode);
    const [isSentVerifiedCode, setIsSentVerifiedCode] = useState(initialStates.isSentVerifiedCode);
    const isElementary = paymentType === PaymentPageType.ElementaryMathExtension;
    const isTextbook = products.every((product) => product.isTextbook);
    const isEnvelope = paymentType === PaymentPageType.EnvelopeMockExamTextbook || paymentType === PaymentPageType.EnvelopeMockExamLecture;
    const merchantType: MerchantType = isTextbook ? 'maesian' : 'ihateflyingbugs';
    const isEnglishPayment = products.every((product) => product.subjects.every((subject) => subject === 'ENGLISH'));
    const isEnglishTextbook = isTextbook && isEnglishPayment;
    const isMathQna = paymentType === PaymentPageType.WEEKEND_QNA_PASS_1M;
    const { startDateOfClass, endDateOfClass } = options;

    const openPaymentDialog = (product) => {
        const productName = product.name;
        googleAnalyticsEvent('click', '연장 상품 카드', `${productName}`);
        setSelectedProduct(product);
        setDialogOpen(true);
    };

    const closePaymentDialog = () => {
        setSelectedProduct(initialStates.selectedProduct);
        setName(initialStates.name);
        setPhoneNumber(initialStates.phoneNumber);
        setPayMethod(initialStates.payMethod);
        setDialogOpen(initialStates.isDialogOpen);
        setAuthCode(initialStates.authCode);
        setIsSentVerifiedCode(initialStates.isSentVerifiedCode);
    };

    const onKeyDownOnPaymentDialog = (event) => {
        if (event.key === 'Escape') closePaymentDialog();
        if (event.key === 'Enter') purchase();
    };

    const onChangeName = (event) => {
        setName(event.target.value);
    };

    const onChangePhoneNumber = (event) => {
        setPhoneNumber(event.target.value);
    };

    const onChangeAuthCode = (event) => {
        setAuthCode(event.target.value);
    };

    const onChangePayMethod = (event, value) => {
        setPayMethod(value);
    };

    const purchase = async () => {
        const productName = (selectedProduct as any).name;

        if (!regExp.name.test(name)) return alert('이름을 다시 확인해주세요.');
        if (!regExp.phoneNumber.test(phoneNumber)) return alert('연락처를 다시 확인해주세요.');
        if (selectedProduct.billingType === ProductBillingType.RECURRING && isDatePlusDaysBeforeToday(new Date(firstDate), selectedProduct.duration)) {
            return alert('개강일 설정이 올바르지 않습니다.');
        }
        googleAnalyticsEvent('click', '연장 결제 시도', productName);

        closePaymentDialog();
        purchaseWithIamport({
            products: [selectedProduct],
            payMethod: payMethod,
            amount: selectedProduct.price,
            buyerName: name,
            buyerTel: phoneNumber,
            queryParams,
            token,
            direct,
            extension: true,
            startDateOfClass,
            endDateOfClass,
            merchantType,
            startDate: firstDate ?? undefined,
        });
    };

    const getProducts = (paymentType, recurring) => {
        ajax({
            type: 'GET',
            url: REST_URL + `/products?productType=1&paymentType=${paymentType}`,
            success: function (result) {
                setProducts(result.data.products);
            },
            contentType: 'application/json',
            dataType: 'json',
        });
    };

    const getTitleImageSource = (isMobile) => {
        if (isMobile) {
            if (isMathQna) return '/images/payment/m_background_1_math_qna.png';
            if (isEnvelope) return '/images/payment/m_background_1_envelope_2.png';
            if (isTextbook) return '/images/payment/m_background_1_textbook.png';
            if (isElementary) {
                return '/images/payment/m_background_1_elementary.png';
            }

            return '/images/payment/m_background_1.jpg';
        } else {
            if (isMathQna) return '/images/payment/background_1_math_qna.png';
            if (isEnvelope) return '/images/payment/background_1_envelope_2.png';
            if (isTextbook) return '/images/payment/background_1_textbook.png';
            if (isElementary) {
                return '/images/payment/background_1_elementary.png';
            }

            return '/images/payment/background_1.jpg';
        }
    };

    const getInvestmentImageSource = (isMobile) => {
        if (isMobile) {
            if (isEnglishTextbook) return '/images/payment/m_background_4_textbook.png';
            return '/images/payment/m_background_4.jpg';
        } else {
            if (isEnglishTextbook) return '/images/payment/background_4_textbook.png';
            return '/images/payment/background_4.jpg';
        }
    };

    const titleImageSource = getTitleImageSource(isMobile);
    const investmentImageSource = getInvestmentImageSource(isMobile);
    const imageStyle = { display: 'block', width: '100%' };

    useEffect(() => {
        const handleContentWidth = () => setContentWidth((window.innerWidth < MAX_DESKTOP_WIDTH ? window.innerWidth : MAX_DESKTOP_WIDTH) / 100);
        window.addEventListener('resize', handleContentWidth);
        return () => window.removeEventListener('resize', handleContentWidth);
    }, []);

    useEffect(() => {
        getProducts(paymentType, recurring);
    }, [paymentType, recurring]);

    useEffect(() => {
        const pathname = window.location.pathname;
        googleAnalyticsConfig('연장 결제 페이지', pathname);
        googleAnalyticsEvent('landing', '연장 결제 페이지', '접속');
    }, []);

    const needFirstDate = products.some((product) => product.billingType === ProductBillingType.MONTHLY_BILLING);
    if (needFirstDate && isNil(firstDate)) return <DafaultPage isLoading={false}>개강일이 지정되지 않았습니다.</DafaultPage>;

    return (
        <div style={{ display: 'flex' }}>
            <div style={{ flex: `${MAX_DESKTOP_WIDTH}px 0 1`, margin: '0 auto' }}>
                <img style={imageStyle} alt="background_1.jpg" src={titleImageSource} />
                <ProductList
                    isMobile={isMobile}
                    products={products}
                    contentWidth={contentWidth}
                    paymentType={paymentType}
                    options={options}
                    openPaymentDialog={openPaymentDialog}
                />
                <img style={imageStyle} alt="background_4.jpg" src={investmentImageSource} />
                <ProductRulesAccordion isTextbook={isTextbook} isEnvelope={isEnvelope} />
                <Footer />
                <PaymentDialog
                    product={selectedProduct}
                    extension={extension}
                    phoneNumber={phoneNumber}
                    name={name}
                    isDialogOpen={isDialogOpen}
                    onChangeName={onChangeName}
                    onChangePhoneNumber={onChangePhoneNumber}
                    onChangePayMethod={onChangePayMethod}
                    onKeyDownOnPaymentDialog={onKeyDownOnPaymentDialog}
                    closePaymentDialog={closePaymentDialog}
                    purchase={purchase}
                    authCode={authCode}
                    onChangeAuthCode={onChangeAuthCode}
                    isSentVerifiedCode={isSentVerifiedCode}
                    setIsSentVerifiedCode={setIsSentVerifiedCode}
                    paymentType={paymentType}
                    firstDate={firstDate}
                />
            </div>
        </div>
    );
}

export default PaymentExtension;

export enum PaymentPageType {
    SpecializedHighSchool = 24,
    ElementaryMathExtension = 54,
    SpectialLectureEnglish = 92,
    SpectialLectureMath = 93,
    PackagedTest = 103,
    ElementaryMath = 127,
    WiterVacation2024English = 132,
    WiterVacation2025English = 1530,
    WinterVacation2024Math = 133,
    SummerVacation2024English = 1208,
    EnvelopeMockExamTextbook = 1334,
    EnvelopeMockExamLecture = 1335,
    WEEKEND_QNA_PASS_1M = 1907,
}

export enum PaymentModuleType {
    NORMAL = 'NORMAL',
    BILLING = 'BILLING',
}

// https://developers.portone.io/api/rest-v1/type-def#PaymentAnnotation
export type PayMethod =
    | 'samsung'
    | 'card'
    | 'trans'
    | 'vbank'
    | 'phone'
    | 'cultureland'
    | 'smartculture'
    | 'booknlife'
    | 'happymoney'
    | 'point'
    | 'ssgpay'
    | 'lpay'
    | 'payco'
    | 'kakaopay'
    | 'tosspay'
    | 'naverpay'
    | 'applepay'
    | 'paypal';

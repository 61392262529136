import React from 'react';
import { ProductCard } from './ProductCard';
import { ProductCardMegaStudy } from './ProductCardMegaStudy';
import { ViewType } from '../../constants/payment-page';
import { isEmpty } from '@fxts/core';

export const ProductList = (props) => {
    const { isMobile, products, contentWidth, paymentType, options, openPaymentDialog } = props;
    const { viewType, productGroups } = options;
    const isHighGrade3ExtensionMegastudy = viewType === ViewType.GRADE_3_EXTENSION;
    const isHighGrade3Megastudy = viewType === ViewType.GRADE_3;
    const isHighGrade3Mega = isHighGrade3ExtensionMegastudy || isHighGrade3Megastudy;

    const productGroupsWithProduct = productGroups.map((productGroup) => {
        productGroup.products = products.filter((product) => product.curriculumType === productGroup.orderIndex);
        return productGroup;
    });

    const getContainerStyle = (isMobile): React.CSSProperties => {
        if (isMobile) {
            return {
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                width: '100%',
                top: 0,
                backgroundColor: 'rgb(240, 240, 240)',
                justifyContent: 'center',
                alignItems: 'center',
            };
        } else {
            return { backgroundColor: 'rgb(240, 240, 240)' };
        }
    };

    const getProductListStyle = (isMobile): React.CSSProperties => {
        if (isMobile) {
            return {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100%',
                padding: '5% 0',
            };
        } else {
            return {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100%',
                padding: '3% 0',
            };
        }
    };

    const getProductListBackgroundStyle = (isMobile): React.CSSProperties => {
        if (isMobile) {
            return { display: 'block', width: '100%' };
        } else {
            return { display: 'block', width: '40%' };
        }
    };

    const getProductListBlockStyle = (isMobile): React.CSSProperties => {
        if (isMobile) {
            return { display: 'inline-flex', width: '100%', alignItems: 'center', flexFlow: 'wrap', marginTop: '6%' };
        } else {
            return { display: 'inline-flex', width: '60%', alignItems: 'center', flexFlow: 'wrap', marginTop: '3%' };
        }
    };

    const getCurriculumTypeTitleStyle = (isMobile): React.CSSProperties => {
        if (isMobile) {
            return {
                position: 'relative',
                fontSize: contentWidth * 6,
                fontWeight: 'bold',
                textAlign: 'center',
                color: 'rgb(120,120,120)',
            };
        } else {
            return {
                position: 'relative',
                fontSize: contentWidth * 2.3,
                fontWeight: 'bold',
                textAlign: 'center',
                color: 'rgb(120,120,120)',
            };
        }
    };

    const getAttendTimeStyle = (isMobile): React.CSSProperties => {
        if (isMobile) {
            return { fontSize: contentWidth * 4.5, color: 'rgb(168,168,168)' };
        } else {
            return { fontSize: contentWidth * 1.7, color: 'rgb(168,168,168)' };
        }
    };

    const getLastAttendTimeStyle = (isMobile): React.CSSProperties => {
        if (isMobile) {
            return { fontSize: contentWidth * 3, color: 'rgb(255,93,169)' };
        } else {
            return { fontSize: contentWidth * 1.1, color: 'rgb(255,93,169)' };
        }
    };

    const getExtraPercentStyle = (isMobile): React.CSSProperties => {
        if (isMobile) {
            return {
                position: 'absolute',
                alignSelf: 'center',
                top: '50%',
                transform: 'translateY(-50%)',
                marginLeft: '4px',
                padding: '2px 4px',
                borderRadius: 4,
                backgroundColor: 'rgb(255,93,169)',
                color: 'rgb(255,255,255)',
                fontSize: contentWidth * 3,
                fontWeight: 'lighter',
                width: 'max-content',
            };
        } else {
            return {
                position: 'absolute',
                alignSelf: 'center',
                top: '50%',
                transform: 'translateY(-50%)',
                marginLeft: '4px',
                padding: '2px 4px',
                borderRadius: 4,
                backgroundColor: 'rgb(255,93,169)',
                color: 'rgb(255,255,255)',
                fontSize: contentWidth * 1,
                fontWeight: 'lighter',
                width: 'max-content',
            };
        }
    };

    const containerStyle = getContainerStyle(isMobile);
    const productListStyle = getProductListStyle(isMobile);
    const productListBackgroundStyle = getProductListBackgroundStyle(isMobile);
    const productListBlockStyle = getProductListBlockStyle(isMobile);
    const curriculumTypeTitleStyle = getCurriculumTypeTitleStyle(isMobile);
    const attendTimeStyle = getAttendTimeStyle(isMobile);
    const lastAttendTimeStyle = getLastAttendTimeStyle(isMobile);
    const extraPercentStyle = getExtraPercentStyle(isMobile);

    return (
        <div style={containerStyle}>
            {productGroupsWithProduct.map((productGroup, key) => {
                if (productGroup.products.length > 0 || isHighGrade3Mega) {
                    return (
                        <div style={productListStyle} key={key}>
                            {!(isEmpty(productGroup.title) && isEmpty(productGroup.attendTime) && isEmpty(productGroup.lastAttendTime)) && (
                                <div style={productListBackgroundStyle}>
                                    {!isEmpty(productGroup.title) && (
                                        <div style={curriculumTypeTitleStyle}>
                                            <span>{productGroup.title} </span>
                                            {productGroup.hasExtraCharge && (
                                                <span style={{ position: 'relative' }}>
                                                    <span style={extraPercentStyle}>20%할증</span>
                                                </span>
                                            )}
                                        </div>
                                    )}
                                    {!(isEmpty(productGroup.attendTime) && isEmpty(productGroup.lastAttendTime)) && (
                                        <div style={{ textAlign: 'center', marginTop: 4 }}>
                                            {!isEmpty(productGroup.attendTime) && <span style={attendTimeStyle}>{productGroup.attendTime} </span>}
                                            {!isEmpty(productGroup.lastAttendTime) && (
                                                <span style={lastAttendTimeStyle}>*마지막 출석 가능 시간 {productGroup.lastAttendTime}</span>
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                            <div style={productListBlockStyle}>
                                {isHighGrade3Mega && (
                                    <ProductCardMegaStudy isMobile={isMobile} contentWidth={contentWidth} paymentType={paymentType} viewType={viewType} />
                                )}
                                {productGroup.products.map((product, key) => {
                                    return (
                                        <ProductCard
                                            key={key}
                                            isMobile={isMobile}
                                            product={product}
                                            contentWidth={contentWidth}
                                            paymentType={paymentType}
                                            openPaymentDialog={openPaymentDialog}
                                            viewType={viewType}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    );
                }
                return <div></div>;
            })}
        </div>
    );
};

import React from 'react';
import { changeCurrencyUnit, getDiscountedPrice, getDiscountPercent } from '../../utils/currency';
import styled from 'styled-components';
import { PaymentPageType } from '../../constants/payment';
import { ViewType } from '../../constants/payment-page';
import { ProductBillingType } from '../../constants/constants';

const Wrapper = styled.div`
    display: inline-block;
    position: relative;
    border: 4px solid #ffffff;
    background-image: url('/images/payment/product_card.jpg');
    background-size: contain;
    box-shadow:
        rgba(0, 0, 0, 0.12) 0px 1px 8px,
        rgba(0, 0, 0, 0.12) 0px 1px 6px;
    cursor: pointer;
    width: ${(props) => (props.isMobile ? '90%' : '48%')};
    margin: ${(props) => (props.isMobile ? '0% 5% 5% 5%' : '1%')};
    border-radius: ${(props) => (props.isMobile ? '5px' : '10px')};
    flex-grow: ${(props) => (props.isHighGrade3Mega ? 1 : 0)};
`;

export const ProductCard = (props) => {
    const { isMobile, product, contentWidth, paymentType, openPaymentDialog, viewType } = props;
    const { name, description, curriculumType, duration, price, originalPrice, refundDate } = product;
    const cardWidth = ((isMobile, contentWidth) => {
        if (isMobile) return contentWidth * 3.1;
        return contentWidth;
    })(isMobile, contentWidth);

    const isHighGrade3Mega = viewType === ViewType.GRADE_3_EXTENSION;
    const isRenewal = paymentType === 7 || paymentType === 8 || paymentType === 16 || paymentType === 17;
    const isNighttimeProduct = curriculumType === 2;
    const isSpecializedHighSchool = paymentType === 24;
    const isInvalid = isRenewal && isNighttimeProduct;
    const isShowOriginalPrice = !(isSpecializedHighSchool && duration === 28);

    return (
        <Wrapper isMobile={isMobile} isHighGrade3Mega={isHighGrade3Mega}>
            <div onClick={isInvalid || openPaymentDialog.bind(null, product)} style={{ cursor: isInvalid ? 'not-allowed' : '' }}>
                <div
                    style={{
                        width: '100%',
                        padding: '5% 10%',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            minHeight: cardWidth * 10.5,
                        }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                            <div>
                                <span
                                    style={{
                                        fontSize: cardWidth * 1.5,
                                        fontWeight: 'bold',
                                        textDecoration: isInvalid ? 'line-through' : '',
                                    }}
                                >
                                    {name}
                                </span>
                                {isInvalid && (
                                    <span
                                        style={{
                                            fontSize: cardWidth * 1,
                                            fontWeight: 'bold',
                                            color: 'hotpink',
                                        }}
                                    >
                                        {' '}
                                        전체 마감
                                    </span>
                                )}
                            </div>
                            {refundDate !== null && refundDate !== 0 && (
                                <div
                                    style={{
                                        fontSize: cardWidth * 1,
                                        color: 'rgb(18,130,254)',
                                    }}
                                >
                                    (100%환불보장 {refundDate}일)
                                </div>
                            )}
                            {description && (
                                <div
                                    style={{
                                        fontSize: cardWidth * 1.1,
                                        color: 'grey',
                                    }}
                                >
                                    {description}
                                </div>
                            )}
                        </div>
                        {isShowOriginalPrice && getDiscountPercent(price, originalPrice) > 0 && (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'baseline',
                                    flexDirection: 'row-reverse',
                                    marginTop: 'auto',
                                    fontSize: cardWidth * 1.3,
                                    color: '#aaa',
                                    fontWeight: 'bold',
                                }}
                            >
                                {changeCurrencyUnit(getDiscountedPrice(price, originalPrice))}원(
                                {getDiscountPercent(price, originalPrice)}%)할인
                            </div>
                        )}
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'baseline',
                                flexDirection: 'row-reverse',
                            }}
                        >
                            <div
                                style={{
                                    fontSize: cardWidth * 1.8,
                                    fontWeight: 'bold',
                                    color: 'hotpink',
                                }}
                            >
                                {product.billingType === ProductBillingType.MONTHLY_BILLING && '매달 '}
                                {changeCurrencyUnit(price)}원
                            </div>
                            {isShowOriginalPrice && getDiscountedPrice(price, originalPrice) > 0 && (
                                <div
                                    style={{
                                        fontSize: cardWidth * 1.3,
                                        flexGrow: 1,
                                        color: '#aaa',
                                        position: 'relative',
                                    }}
                                >
                                    <img
                                        alt={'arrow'}
                                        style={{
                                            position: 'absolute',
                                            bottom: cardWidth * 0.7,
                                            width: '100%',
                                        }}
                                        src={'/images/payment/arrow.png'}
                                    />
                                    {changeCurrencyUnit(originalPrice)}원
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};

import React from 'react';
import styled from 'styled-components';
import { PaymentPageOptions, ProductGroupWithProductSets } from '../../constants/payment-page';
import { isEmpty } from '@fxts/core';
import { ProductSetCard } from './ProductSetCard';
import { ProductSet } from '../../constants/product-set';

const Wrapper = styled.div`
    background-color: rgb(240, 240, 240);
    display: ${(props) => (props.isMobile ? 'flex' : 'block')};
    flex-direction: ${(props) => (props.isMobile ? 'column' : 'unset')};
    position: ${(props) => (props.isMobile ? 'relative' : 'static')};
    width: ${(props) => (props.isMobile ? '100%' : 'auto')};
    top: ${(props) => (props.isMobile ? '0' : 'unset')};
    justify-content: ${(props) => (props.isMobile ? 'center' : 'unset')};
    align-items: ${(props) => (props.isMobile ? 'center' : 'unset')};
`;

const ProductList = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding-top: ${(props) => (props.isMobile ? 'unset' : '5%')};
`;

const ProductListBackground = styled.div`
    display: block;
    width: ${(props) => (props.isMobile ? '100%' : '40%')};
    margin-top: ${(props) => (props.isMobile ? '10%' : 'unset')};
`;

const CurriculumTypeTitle = styled.div`
    position: relative;
    font-weight: bold;
    text-align: center;
    color: rgb(120, 120, 120);
    font-size: ${(props) => (props.isMobile ? props.contentWidth * 1 : props.contentWidth * 6)};
`;

const ProductListBlock = styled.div`
    display: inline-flex;
    align-items: center;
    flex-flow: wrap;
    margin-top: ${(props) => (props.isMobile ? '6%' : '3%')};
    width: ${(props) => (props.isMobile ? '100%' : '60%')};
`;

const RefundGuide = styled.div`
    text-align: center;
    font-size: ${(props) => (props.isMobile ? props.contentWidth * 2.5 : props.contentWidth * 1)}px;
    color: #aaa;
    padding: ${(props) => (props.isMobile ? '10% 0% 6% 0%' : '5% 0% 3% 0%')};
`;

const AttendTime = styled.span`
    font-size: ${(props) => (props.isMobile ? props.contentWidth * 4.5 : props.contentWidth * 1.7)}px;
    color: rgb(168, 168, 168);
`;

const LastAttendTime = styled.span`
    font-size: ${(props) => (props.isMobile ? props.contentWidth * 3 : props.contentWidth * 1.1)}px;
    color: rgb(255, 93, 169);
`;

const ExtraPercentWrapper = styled.span`
    position: relative;
`;

const ExtraPercent = styled.span`
    position: absolute;
    align-self: center;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 4px;
    padding: 2px 4px;
    border-radius: 4px;
    background-color: rgb(255, 93, 169);
    color: rgb(255, 255, 255);
    font-size: ${(props) => (props.isMobile ? props.contentWidth * 3 : props.contentWidth * 1)}px;
    font-weight: lighter;
    width: max-content;
`;

interface ProductSetListProps {
    isMobile: boolean;
    productSets: ProductSet[];
    contentWidth: number;
    paymentType: number;
    options: PaymentPageOptions;
    openPaymentDialog: (productSet: ProductSet) => void;
}

export const ProductSetList = (props: ProductSetListProps) => {
    const { isMobile, productSets: productSetsOrigin, contentWidth, paymentType, options, openPaymentDialog } = props;
    const { viewType, productGroups } = options;

    const productGroupsWithProductSets: ProductGroupWithProductSets[] = productGroups.map((productGroup) => {
        const productSets = productSetsOrigin.filter((product) => product.productGroupId === productGroup.orderIndex);
        return { ...productGroup, productSets };
    });

    return (
        <Wrapper isMobile={isMobile}>
            {productGroupsWithProductSets.map((productGroup, key) => {
                if (productGroup.productSets.length > 0) {
                    return (
                        <ProductList key={key} isMobile={isMobile}>
                            {!(isEmpty(productGroup.title) && isEmpty(productGroup.attendTime) && isEmpty(productGroup.lastAttendTime)) && (
                                <ProductListBackground isMobile={isMobile}>
                                    {!isEmpty(productGroup.title) && (
                                        <CurriculumTypeTitle isMobile={isMobile} contentWidth={contentWidth}>
                                            <span>{productGroup.title} </span>
                                            {productGroup.hasExtraCharge && (
                                                <ExtraPercentWrapper>
                                                    <ExtraPercent isMobile={isMobile} contentWidth={contentWidth}>
                                                        20%할증
                                                    </ExtraPercent>
                                                </ExtraPercentWrapper>
                                            )}
                                        </CurriculumTypeTitle>
                                    )}
                                    {!(isEmpty(productGroup.attendTime) && isEmpty(productGroup.lastAttendTime)) && (
                                        <div style={{ textAlign: 'center', marginTop: 4 }}>
                                            {!isEmpty(productGroup.attendTime) && (
                                                <AttendTime isMobile={isMobile} contentWidth={contentWidth}>
                                                    {productGroup.attendTime}{' '}
                                                </AttendTime>
                                            )}
                                            {!isEmpty(productGroup.lastAttendTime) && (
                                                <LastAttendTime isMobile={isMobile} contentWidth={contentWidth}>
                                                    *마지막 출석 가능 시간 {productGroup.lastAttendTime}
                                                </LastAttendTime>
                                            )}
                                        </div>
                                    )}
                                </ProductListBackground>
                            )}
                            <ProductListBlock isMobile={isMobile}>
                                {productGroup.productSets.map((productSet, key) => (
                                    <ProductSetCard
                                        key={key}
                                        isMobile={isMobile}
                                        productSet={productSet}
                                        contentWidth={contentWidth}
                                        openPaymentDialog={openPaymentDialog}
                                    />
                                ))}
                            </ProductListBlock>
                        </ProductList>
                    );
                }
                return null;
            })}
        </Wrapper>
    );
};

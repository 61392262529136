import { DateTime } from 'luxon';
import { isNil } from '@fxts/core';
import { is } from 'date-fns/locale';

type WeekDay = 'Sunday' | 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday';
const weekDays: WeekDay[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const MAX_DAY_OF_MONTH_COUNT = 31;

export const calculateDateAfterMonth = (baseDate: DateTime): DateTime => {
    const lastDay = getLastDayOfMonth(baseDate.toJSDate());
    return baseDate.set({ day: lastDay });
};

export const countDaysOfWeekBetweenDates = (date: Date, billingDay: number): number => {
    const startDate = DateTime.fromJSDate(date);

    const standardDate = DateTime.fromJSDate(getStandardDate(date));
    const endDate = calculateDateAfterMonth(standardDate);

    const count = Array.from({ length: endDate.diff(startDate, 'days').days + 1 }, (_, index) => startDate.plus({ days: index })).length;

    if (getIsBillingDayPassed(standardDate.toJSDate(), billingDay)) return count + MAX_DAY_OF_MONTH_COUNT;
    return count;
};

export const getLastDayOfMonth = (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    return new Date(year, month + 1, 0).getDate();
};

export const getMonthDates = (currentDate: Date): Date[] => {
    const firstDayOfMonth = DateTime.fromJSDate(currentDate).startOf('month');
    const lastDayOfMonth = DateTime.fromJSDate(currentDate).endOf('month');

    const daysInMonth: Date[] = [];
    let currentDay = firstDayOfMonth;

    // 이전 달의 마지막 주의 일자부터 시작
    while (currentDay.weekday !== 7) {
        currentDay = currentDay.minus({ days: 1 });
        daysInMonth.unshift(currentDay.toJSDate());
    }

    // 현재 달의 날짜 추가
    currentDay = firstDayOfMonth;
    while (currentDay <= lastDayOfMonth) {
        daysInMonth.push(currentDay.toJSDate());
        currentDay = currentDay.plus({ days: 1 });
    }

    // 다음 달의 시작 부분 추가
    while (currentDay.weekday !== 7) {
        daysInMonth.push(currentDay.toJSDate());
        currentDay = currentDay.plus({ days: 1 });
    }

    return daysInMonth;
};

export const getFirstBillingDateOnStartDateOfClass = (startDateOfClass: Date, monthlyBillingDay: number): DateTime => {
    const startDate = DateTime.fromJSDate(startDateOfClass);
    const startYearMonth = startDate.toFormat('yyyy-MM');
    const formattedbillingDay = DateTime.fromObject({ day: monthlyBillingDay }).toFormat('dd');
    return DateTime.fromFormat(`${startYearMonth}-${formattedbillingDay}`, 'yyyy-MM-dd');
};

export const getIsBillingDayPassed = (date: Date, monthlyBillingDay: number): boolean => {
    const standardDate = DateTime.fromJSDate(date);
    const firstBillingDate = getFirstBillingDateOnStartDateOfClass(standardDate.toJSDate(), monthlyBillingDay);
    return firstBillingDate <= standardDate;
};

export const getNextBillingDate = (startDateOfClass: Date, monthlyBillingDay: number): DateTime => {
    const standardDate = getStandardDate(startDateOfClass);
    const firstBillingDate = getFirstBillingDateOnStartDateOfClass(standardDate, monthlyBillingDay);
    if (getIsBillingDayPassed(standardDate, monthlyBillingDay)) return firstBillingDate.plus({ month: 1 });
    return firstBillingDate;
};

export const getLastClassDate = (startDateOfClass: Date, monthlyBillingDay: number): DateTime => {
    const standardDate = getStandardDate(startDateOfClass);
    const isPassedBillingDay = getIsBillingDayPassed(new Date(standardDate), monthlyBillingDay);
    if (isPassedBillingDay) return DateTime.fromJSDate(standardDate).plus({ months: 1 }).endOf('month');
    return DateTime.fromJSDate(standardDate).endOf('month');
};

// 월별 결제시 다음 정기결제일 기준이 되는 시간을 반환합니다.
// 시작일이 현재날짜보다 이전인 경우 현재날짜를 반환합니다.
// 시작일이 현재날짜이거나 그 이후인 경우 시작일 반환합니다.
export const getStandardDate = (startDateOfClass: Date, currentDate: Date = new Date()): Date => {
    const startDateWithoutTime = DateTime.fromJSDate(startDateOfClass).startOf('day').toJSDate();
    const currentDateWithoutTime = DateTime.fromJSDate(currentDate).startOf('day').toJSDate();
    return startDateWithoutTime < currentDateWithoutTime ? currentDateWithoutTime : startDateWithoutTime;
};

export const isDatePlusDaysBeforeToday = (date: Date, days: number): boolean => {
    if (isNil(date)) return false;
    const baseDate = DateTime.fromJSDate(date).startOf('day');
    const targetDate = baseDate.plus({ days });
    const today = DateTime.fromJSDate(new Date());

    return targetDate <= today;
};

import { PurchaseType } from './purchase-page';

export interface TotalShopSection {
    title: string;
    description: string;
    math?: SubjectSection;
    english?: SubjectSection;
    korean?: SubjectSection;
}

interface SubjectSection {
    title: string;
    description: string;
    color: string;
    purchaseType: PurchaseType;
    imageUrl?: string;
}

export const TotalShopSectionList: TotalShopSection[] = [
    {
        title: '초등 · 중학생 PT',
        description: '맞춤 관리로 공부습관 형성',
        math: {
            title: '수학PT',
            description: '확실한 1:1 학습케어',
            color: '#48B6FF',
            purchaseType: PurchaseType.TOTAL_SHOP_MIDDLE_MATH,
            imageUrl: '/images/shop/icon-middle-math.png',
        },
        english: {
            title: '영어PT',
            description: '확실한 1:1 학습케어',
            color: '#FF779F',
            purchaseType: PurchaseType.TOTAL_SHOP_MIDDLE_ENGLISH,
            imageUrl: '/images/shop/icon-middle-english.png',
        },
    },
    {
        title: '고1 전용 PT',
        description: '약한 부분 집중 공략',
        math: {
            title: '고1수학PT',
            description: '완벽한 관리로 1등급 완성',
            color: '#48B6FF',
            purchaseType: PurchaseType.TOTAL_SHOP_HIGH_1_MATH,
            imageUrl: '/images/shop/icon-high-1-math.png',
        },
        english: {
            title: '고1영어PT',
            description: '완벽한 관리로 1등급 완성',
            color: '#FF779F',
            purchaseType: PurchaseType.TOTAL_SHOP_HIGH_1_ENGLISH,
            imageUrl: '/images/shop/icon-high-1-english.png',
        },
    },
    {
        title: '고2 전용 PT',
        description: '약한 부분 집중 공략',
        math: {
            title: '고2수학PT',
            description: '완벽한 관리로 1등급 완성',
            color: '#48B6FF',
            purchaseType: PurchaseType.TOTAL_SHOP_HIGH_2_MATH,
            imageUrl: '/images/shop/icon-high-2-math.png',
        },
        english: {
            title: '고2영어PT',
            description: '완벽한 관리로 1등급 완성',
            color: '#FF779F',
            purchaseType: PurchaseType.TOTAL_SHOP_HIGH_2_ENGLISH,
            imageUrl: '/images/shop/icon-high-2-english.png',
        },
    },
    {
        title: '고3 전용 PT',
        description: '성공적인 입시를 향한 첫 걸음',
        math: {
            title: '고3수학PT',
            description: '수학 인생점수 만들기',
            color: '#48B6FF',
            purchaseType: PurchaseType.TOTAL_SHOP_HIGH_3_MATH,
            imageUrl: '/images/shop/icon-high-3-math.png',
        },
        english: {
            title: '고3영어PT',
            description: '영어 인생점수 만들기',
            color: '#FF779F',
            purchaseType: PurchaseType.TOTAL_SHOP_HIGH_3_ENGLISH,
            imageUrl: '/images/shop/icon-high-3-english.png',
        },
    },
];

export const TotalShop2SectionList: TotalShopSection[] = [
    {
        title: '초등 · 중학생 PT',
        description: '맞춤 관리로 공부습관 형성',
        math: {
            title: '수학PT',
            description: '확실한 1:1 학습케어',
            color: '#48B6FF',
            purchaseType: PurchaseType.TOTAL_SHOP_2_MIDDLE_MATH,
            imageUrl: '/images/shop/icon-middle-math.png',
        },
        english: {
            title: '영어PT',
            description: '확실한 1:1 학습케어',
            color: '#FF779F',
            purchaseType: PurchaseType.TOTAL_SHOP_2_MIDDLE_ENGLISH,
            imageUrl: '/images/shop/icon-middle-english.png',
        },
    },
    {
        title: '고1 전용 PT',
        description: '약한 부분 집중 공략',
        math: {
            title: '고1수학PT',
            description: '완벽한 관리로 1등급 완성',
            color: '#48B6FF',
            purchaseType: PurchaseType.TOTAL_SHOP_2_HIGH_1_MATH,
            imageUrl: '/images/shop/icon-high-1-math.png',
        },
        english: {
            title: '고1영어PT',
            description: '완벽한 관리로 1등급 완성',
            color: '#FF779F',
            purchaseType: PurchaseType.TOTAL_SHOP_2_HIGH_1_ENGLISH,
            imageUrl: '/images/shop/icon-high-1-english.png',
        },
    },
    {
        title: '고2 전용 PT',
        description: '약한 부분 집중 공략',
        math: {
            title: '고2수학PT',
            description: '완벽한 관리로 1등급 완성',
            color: '#48B6FF',
            purchaseType: PurchaseType.TOTAL_SHOP_2_HIGH_2_MATH,
            imageUrl: '/images/shop/icon-high-2-math.png',
        },
        english: {
            title: '고2영어PT',
            description: '완벽한 관리로 1등급 완성',
            color: '#FF779F',
            purchaseType: PurchaseType.TOTAL_SHOP_2_HIGH_2_ENGLISH,
            imageUrl: '/images/shop/icon-high-2-english.png',
        },
    },
    {
        title: '고3 전용 PT',
        description: '성공적인 입시를 향한 첫 걸음',
        math: {
            title: '고3수학PT',
            description: '수학 인생점수 만들기',
            color: '#48B6FF',
            purchaseType: PurchaseType.TOTAL_SHOP_2_HIGH_3_MATH,
            imageUrl: '/images/shop/icon-high-3-math.png',
        },
        english: {
            title: '고3영어PT',
            description: '영어 인생점수 만들기',
            color: '#FF779F',
            purchaseType: PurchaseType.TOTAL_SHOP_2_HIGH_3_ENGLISH,
            imageUrl: '/images/shop/icon-high-3-english.png',
        },
    },
    {
        title: '고등학생 PT',
        description: '약한 부분 집중 공략',
        korean: {
            title: '국어PT',
            description: '중3-고등/N수 완벽한 관리로 1등급 완성',
            color: '#F3BB40',
            purchaseType: PurchaseType.TOTAL_SHOP_2_HIGH_KOREAN,
            imageUrl: '/images/shop/icon-high-3-math.png',
        },
    },
];

export const TotalShopWeekendSectionList: TotalShopSection[] = [
    {
        title: '중학생 PT',
        description: '맞춤 관리로 공부습관 형성',
        math: {
            title: '수학PT',
            description: '확실한 1:1 학습케어',
            color: '#48B6FF',
            purchaseType: PurchaseType.TOTAL_SHOP_MIDDLE_MATH_WEEKEND,
            imageUrl: '/images/shop/icon-middle-math.png',
        },
        english: {
            title: '영어PT',
            description: '확실한 1:1 학습케어',
            color: '#FF779F',
            purchaseType: PurchaseType.TOTAL_SHOP_MIDDLE_ENGLISH_WEEKEND,
            imageUrl: '/images/shop/icon-middle-english.png',
        },
    },
    {
        title: '고등학생 PT',
        description: '약한 부분 집중 공략',
        math: {
            title: '수학PT',
            description: '완벽한 관리로 1등급 완성',
            color: '#48B6FF',
            purchaseType: PurchaseType.TOTAL_SHOP_HIGH_MATH_WEEKEND,
            imageUrl: '/images/shop/icon-high-1-math.png',
        },
        english: {
            title: '영어PT',
            description: '완벽한 관리로 1등급 완성',
            color: '#FF779F',
            purchaseType: PurchaseType.TOTAL_SHOP_HIGH_ENGLISH_WEEKEND,
            imageUrl: '/images/shop/icon-high-1-english.png',
        },
        korean: {
            title: '국어PT',
            description: '수능 국어 완전 점령',
            color: '#F3BB40',
            purchaseType: PurchaseType.TOTAL_SHOP_HIGH_KOREAN_WEEKEND,
            imageUrl: '/images/shop/icon-high-2-math.png',
        },
    },
];

export const TotalShopPromotionSectionList: TotalShopSection[] = [
    {
        title: '초등 · 중학생 PT',
        description: '맞춤 관리로 공부습관 형성',
        math: {
            title: '수학PT',
            description: '확실한 1:1 학습케어',
            color: '#1CD8AC',
            purchaseType: PurchaseType.TOTAL_SHOP_PROMOTION_MIDDLE_MATH,
            imageUrl: '/images/shop/icon-middle-math.png',
        },
        english: {
            title: '영어PT',
            description: '확실한 1:1 학습케어',
            color: '#FEC844',
            purchaseType: PurchaseType.TOTAL_SHOP_PROMOTION_MIDDLE_ENGLISH,
            imageUrl: '/images/shop/icon-middle-english.png',
        },
    },
    {
        title: '고1 전용 PT',
        description: '약한 부분 집중 공략',
        math: {
            title: '고1수학PT',
            description: '완벽한 관리로 1등급 완성',
            color: '#5BBEFF',
            purchaseType: PurchaseType.TOTAL_SHOP_PROMOTION_HIGH_1_MATH,
            imageUrl: '/images/shop/icon-high-1-math.png',
        },
        english: {
            title: '고1영어PT',
            description: '완벽한 관리로 1등급 완성',
            color: '#FF9065',
            purchaseType: PurchaseType.TOTAL_SHOP_PROMOTION_HIGH_1_ENGLISH,
            imageUrl: '/images/shop/icon-high-1-english.png',
        },
    },
    {
        title: '고2 전용 PT',
        description: '약한 부분 집중 공략',
        // math: {
        //     title: '고2수학PT',
        //     description: '완벽한 관리로 1등급 완성',
        //     color: '#797DFA',
        //     purchaseType: PurchaseType.TOTAL_SHOP_PROMOTION_HIGH_2_MATH,
        //     imageUrl: '/images/shop/icon-high-2-math.png',
        // },
        english: {
            title: '고2영어PT',
            description: '완벽한 관리로 1등급 완성',
            color: '#FF779F',
            purchaseType: PurchaseType.TOTAL_SHOP_PROMOTION_HIGH_2_ENGLISH,
            imageUrl: '/images/shop/icon-high-2-english.png',
        },
    },
    // {
    //     title: '고3 전용 PT',
    //     description: '성공적인 입시를 향한 첫 걸음',
    //     math: {
    //         title: '고3수학PT',
    //         description: '수학 인생점수 만들기',
    //         color: '#4983F5',
    //         purchaseType: PurchaseType.TOTAL_SHOP_PROMOTION_HIGH_3_MATH,
    //         imageUrl: '/images/shop/icon-high-3-math.png',
    //     },
    //     english: {
    //         title: '고3영어PT',
    //         description: '영어 인생점수 만들기',
    //         color: '#D16FF4',
    //         purchaseType: PurchaseType.TOTAL_SHOP_PROMOTION_HIGH_3_ENGLISH,
    //         imageUrl: '/images/shop/icon-high-3-english.png',
    //     },
    // },
];

export const TotalShopPromotionWeekendSectionList: TotalShopSection[] = [
    {
        title: '중학생 PT',
        description: '맞춤 관리로 공부습관 형성',
        math: {
            title: '수학PT',
            description: '확실한 1:1 학습케어',
            color: '#1CD8AC',
            purchaseType: PurchaseType.TOTAL_SHOP_PROMOTION_MIDDLE_MATH_WEEKEND,
            imageUrl: '/images/shop/icon-middle-math.png',
        },
        english: {
            title: '영어PT',
            description: '확실한 1:1 학습케어',
            color: '#FEC844',
            purchaseType: PurchaseType.TOTAL_SHOP_PROMOTION_MIDDLE_ENGLISH_WEEKEND,
            imageUrl: '/images/shop/icon-middle-english.png',
        },
    },
    {
        title: '고등학생 PT',
        description: '약한 부분 집중 공략',
        math: {
            title: '수학PT',
            description: '완벽한 관리로 1등급 완성',
            color: '#5BBEFF',
            purchaseType: PurchaseType.TOTAL_SHOP_PROMOTION_HIGH_MATH_WEEKEND,
            imageUrl: '/images/shop/icon-high-1-math.png',
        },
        english: {
            title: '영어PT',
            description: '완벽한 관리로 1등급 완성',
            color: '#FF9065',
            purchaseType: PurchaseType.TOTAL_SHOP_PROMOTION_HIGH_ENGLISH_WEEKEND,
            imageUrl: '/images/shop/icon-high-1-english.png',
        },
    },
];
